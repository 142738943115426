import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';
import TopSection from 'redux/modules/Cashback/TopSection/container';
import Trustpilot from 'redux/modules/Cashback/Trustpilot/container';
import Retailers from 'redux/modules/Cashback/Retailers/container';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import { get } from 'lodash';
import NewCBCChallengeDialog from 'modules/NewCashback/Challenge/Dialog';

import Stats from './Stats';
import HowItWorks from './HowItWorks';
import BottomCarousel from './BottomCarousel';
import Terms from './Terms';
import useStyles from './styles';

const Cashback = ({
  fetchRetailers,
  skip,
  addCashbackCard,
  removeCashbackCard,
  location,
  isLoading,
}) => {
  const analytics = useTracking();
  const theme = useTheme();
  const classes = useStyles();
  const showCBCChallengeDialog = get(location, 'hash') === '#cbc';

  useEffect(() => {
    fetchRetailers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    analytics.track(EVENTS.CBC_ADD_CLICKED, {
      event_type: EVENT_TYPE.BEHAVIOUR,
    });
    addCashbackCard();
  };
  const handleSkip = () => {
    analytics.track(EVENTS.CBC_NO_THANKS_CLICKED, {
      event_type: EVENT_TYPE.BEHAVIOUR,
    });
    removeCashbackCard();
    skip();
  };
  return (
    <>
      <NewCBCChallengeDialog open={showCBCChallengeDialog} />
      <TopSection />
      <Stats />
      <HowItWorks />
      <Retailers />
      {false && <Trustpilot />}
      <BottomCarousel />
      <Terms />
      <NavigationPane
        helpCtaEnabled
        marginTopZero
        back
        next
        nextLabel="Add Cashback Card"
        nextHandler={handleAdd}
        nextdataTestId="CashBackCardUpsell"
        topColor={theme.palette.common.white}
        loading={isLoading}
      >
        <Button
          variant="outlined"
          onClick={handleSkip}
          color="secondary"
          className={classes.button}
          data-test-id="CashbackCardNoThanks"
        >
          Skip
        </Button>
      </NavigationPane>
    </>
  );
};

Cashback.propTypes = {
  skip: PropTypes.func.isRequired,
  addCashbackCard: PropTypes.func.isRequired,
  removeCashbackCard: PropTypes.func.isRequired,
  fetchRetailers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Cashback;
